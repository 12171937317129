$main-red: #ED1B34;
$dark-red: #b40d1e;
$pink: #fbd1d6;
$orange: #fb8330;
$dark-grey: #333333;
$white: #ffffff;

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

@font-face {
  font-family: kapra-neue;
  src: url(fonts/KapraNeueCustom-Bold.otf);
  font-display: swap;
}

@font-face {
  font-family: avenir;
  src: url(fonts/AvenirLTStd-Book.otf);
  font-display: swap;
}

.App-header{
  background-color: $main-red;
  max-height: fit-content;
  font-family: kapra-neue;
  position: relative;
  color: white;
  font-size: 0.9em;
  .row{
    max-width: 100%;
  }
  .header-text{
    text-transform: uppercase;
  }
  .nav-item:hover{
    background-color: $pink;
    color: $main-red;
    border-radius: 10px 10px 0px 0px;
  
  }
  .nav-item-selected{
    background-color: $pink;
    color: $main-red;
  }
  .nav-item-not-selected{
    background-color: transparent;
    color:$white;
  }
  .nav-link{
    text-decoration: none;
    color: $white;
  }
  .nav-link:hover{
    text-decoration: none;
    color: $main-red;
  }
  .nav-text{
    font-family: avenir;
    text-decoration: none;
    color: $white;
  }
}

@media screen and (max-width: 500px){
  .App-header{
    min-height: fit-content;
    .row{
      margin-left: 0%;
      margin-right: 0%;
      padding-top: 2.0em;
    }  
    .container{
      max-width:100%;
      margin-left: 0%;
      margin-right: 0%;
      padding-right: 0%;
      padding-left: 0%;
    }
    .header-text{
      max-width: 40%;
      padding-bottom: 1em;
      padding-right: 0%;
      font-size: 2.0em;
      margin-left: 5%;
      margin-right: 0%;
      margin-top: auto;
      margin-bottom: auto;
    }
    .nav-container{
      max-width: 50%;
      bottom: 10px;
      margin-right: 0%;
      padding-right: 0%;
      margin-left: auto;
      min-height: fit-content;
      max-height: 100%;
    }
    .navbar {
      max-height: fit-content;
      margin-left: auto;
      margin-right: 0%;
      padding-right: 0%;
      width: 100%;
    }
    .container, .container-fluid{
      padding-right: 0%;
    }
    .toggler{
      display: block;
       margin-left: auto;
      margin-right: auto;
    }
    .nav-item{
      display:inline-block;
      margin-left: 0%;
      margin-right: 0%;
      padding-left: 10px;
      padding-right: 40px;
      text-decoration: none;
      border-radius: 20px 0 0px 20px;
      transition: border-radius 0.5s;
      color: $white;
    }
    
    .nav-item:hover{
      background-color: $pink;
      color: $main-red;
      border-radius: 10px 0px 0px 10px;
    
    }
  }
}

@media screen and (max-width: 767px) and (min-width: 500px){
  .App-header{
    height: fit-content;
    
  .row{
    margin-left: 0%;
    margin-right: 0%;
    padding-top: 2.0em;
  }  
  .container{
    max-width:100%;
    margin-left: 0%;
    margin-right: 0%;
    padding-right: 0%;
    padding-left: 0%;
  }
  .header-text{
    max-width: 50%;
    padding-bottom: 1em;
    font-size: 2.0em;
    margin-left: 5%;
    margin-right: 0%;
    margin-top: auto;
    margin-bottom: auto;
  }
  .nav-container{
    max-width: 50%;
    bottom: 10px;
    margin-right: 0%;
    padding-right: 0%;
    margin-left: auto;
    min-height: fit-content;
    max-height: 100%;
  }
  .navbar {
    max-height: fit-content;
    margin-left: auto;
    margin-right: 0%;
    padding-right: 0%;
    width: 100%;
  }
  .container, .container-fluid{
    padding-right: 0%;
  }
  .toggler{
    display: block;
     margin-left: auto;
    margin-right: auto;
  }
  .nav-item{
    display:inline-block;
    margin-left: 0%;
    margin-right: 0%;
    padding-left: 10px;
    padding-right: 40px;
    text-decoration: none;
    border-radius: 20px 0 0px 20px;
    transition: border-radius 0.5s;
    color: $white;
  }
  
  .nav-item:hover{
    background-color: $pink;
    color: $main-red;
    border-radius: 10px 0px 0px 10px;
  
  }

}
}
@media screen and (min-width:768px){
.App-header {
  .container{
    max-width:100%;
    bottom:0px;
  }
  .header-text{
    font-size: 2.5em;
    padding-left: 1.2em;
    padding-top: 1.0em;
  }
  .navbar {
    padding: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  .nav-item{
    display:inline-block;
    margin-left: 0.5%;
    margin-right: 0.5%;
    text-decoration: none;
    border-radius: 20px 20px 0px 0px;
    transition: border-radius 0.5s;
    color: $white;
  }
  .nav-container{
    bottom: 0px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
  }
  

}
}
}

.example-enter {
  opacity: 0.0;
}

.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter-done{
  opacity: 1;
  transition: opacity 1000ms ease 1000ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit{
  opacity: 0;
  transition: opacity 5000ms linear;
}

.fade-exit-done {
  opacity: 0;
}

.particles{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 0;
}

.pink{
  position: absolute;
  background-color: $pink;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 0;
}

.App-main{
  margin-top: 0%;
  min-height: 600px;
  max-height: fit-content;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  font-weight: 500;
  font-family: garamond;
  position: relative;
  color: $dark-grey;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 50px;
  .container{
    max-width: 95%;
    padding-left: 0%;
    padding-right: 0%;
    position: relative;
  }
  h1{
    font-family: kapra-neue;
    text-align: center;
    max-width: max-content;
    color: $main-red;
    font-size: 2.4em;
    z-index: 10;
    text-transform: uppercase;
    padding-top: 7%;
    padding-bottom: 3%;
  }
  p{
    padding-right: 20px;
  }
  h2{
    font-size: 1.1em;
    font-weight: 600;
    font-family: avenir;
    text-transform: uppercase;
    color: $main-red;
    
  }
  ul{
    list-style-type: none;
    list-style: none;
    font-size: 1.2;
    font-family: avenir;
    margin-left: 0px;
    padding-left: 0px;
    li{
      margin-bottom: 10px;
    }
  }
}

.top-video-container{
  max-width: 100%;
  min-width: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
  margin: 0%;
  padding: 5% 0% 0% 0%;
  
  video{
    margin: 0%;
    padding: 0%;
    border-radius: 3%;
  .top-video{
    max-width: 100%;
    margin: 0%;
    padding: 0%;
  }
}
}
.image-container{
  max-width: 600px;
  min-width: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
  margin: 0%;
  padding: 5% 0% 0% 0%;
  .img-in-text{
    opacity: 1;
    transition: opacity .3s;
    background-position: center;
    object-fit: cover;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 400px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 0%;
    border-radius: 2%;
  }
}

.App-footer{
  background-color: $main-red;
  bottom: 0px;
  padding-top: 50px;
  min-height: 300px;
  max-height: fit-content;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: $dark-grey;
  .bottom-logo{
    margin-right: 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
  }
  .logo-container{
    text-align: center;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    padding-top: 15px;
    padding-bottom: 30px;
    img{
      width: 33%;
    }
  }
  .footer-text{
    text-align: center;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    font-size: 1.2em;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  h3{
    font-size: 1.5em;
    color: $white;
    font-weight: 500;
  }
  p{
    color: $white;
    margin-top: 1px;
    margin-bottom: 1px;
  }
  a{
    text-decoration: underline;
    color: $white;
    font-size: 1.5em;
  }
  
}


